import { Dispatch, SetStateAction } from "react";

export function zfTableDefaultOptions(
  handleError: (error: boolean) => void
): any {
  return {
    rowHeight: 30,
    layout: "fitColumns",
    autoColumns: false,
    pagination: true,
    paginationMode: "remote",
    ajaxURL: "http://example.com",
    /*ajaxConfig: {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        },*/
    dataLoaderError: () => {
      //handleError(true);
      console.log("error");
    },
    paginationSize: 5,
    paginationSizeSelector: [3, 5, 10, 20, 50, 100],
    paginationInitialPage: 0,
    paginationCounter: function (pageSize: number, currentRow: number, currentPage: number, totalRows: number, totalPages: number) {
      return "Showing " + pageSize + " rows of " + totalRows + " total";
    },
    filterMode: "remote",
    sortMode: "remote",
    dataLoader: false,
    ajaxResponse: (url: string, params: any, response: any) => {
      console.log("zf-table ajax response: \n", response);
      const tableData = {
        data: response._embedded?.entities,
        last_page: response.page?.totalPages,
      };
      return tableData;
    },
  };
}

export function tableBuiltHandler(
  event: CustomEvent<any>,
  setApiFetchingState: Dispatch<SetStateAction<boolean>>,
  cellEditedCallBack?: (cell: any, table: any) => void
) {
  const table = event.detail;
  table.setData();

  if (cellEditedCallBack) {
    table.on("cellEdited", function (cell: any) {
      cellEditedCallBack(cell, table);
    });
  }

  table.on("dataLoading", function (data: any) {
    setApiFetchingState(true);
    console.log("DEBUG - zfTable event 'dataLoading'");
  });

  table.on("dataLoaded", (data: any) => {
    dataLoaded(data);
    setApiFetchingState(false);
  });

  table.on("renderComplete", renderComplete);

  table.on("renderStarted", function () {
    console.log("DEBUG - zfTable event 'renderStarted'");
  });
}

export function dataLoaded(data: any) {
  //console.log("DEBUG - zfTable event 'dataLoaded'");
}

export function renderComplete() {
  //console.log("DEBUG - zfTable event 'renderComplete'");
}
