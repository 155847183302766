import { useNavigate, useParams } from "react-router-dom";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import {
  ZfInputField,
  ZfLoadingSpinner,
  ZfSelectField,
  ZfSelectOption,
  ZfTextButton,
  ZfTextareaField,
} from "@ccx/zafire-react";
import { useEffect, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import { useAppDispatch } from "../../store/store";
import { AppError, appStateActions } from "../../store/appstate-slice";
import {
  NostroCategoryControllerService,
  NostroCategoryDto,
} from "../../openapi";

interface FormInput {
  value: any;
  textError?: string;
  state?: "invalid" | "readonly";
}

interface FormData {
  createUser: FormInput;
  createTs: FormInput;
  updateUser: FormInput;
  updateTs: FormInput;
  statusId: FormInput;
  statusText: FormInput;
  id: FormInput;
  code: FormInput;
  text: FormInput;
  comment: FormInput;
  [key: string]: FormInput["value"];
}

function initFormaData(): FormData {
  return {
    createUser: { value: "" },
    createTs: { value: "" },
    updateUser: { value: "" },
    updateTs: { value: "" },
    statusId: { value: 0 },
    statusText: { value: "" },
    id: { value: 0 },
    code: { value: "" },
    text: { value: "" },
    comment: { value: "" },
  };
}

interface NostroCategoryDtoIdx extends NostroCategoryDto {
  [key: string]: any;
}

const AccountCategoriesEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [accCatFetching, setAccCatFetching] = useState(false);

  const { id } = useParams();

  //const [formData, setformData] = useState<EntityModelNostroCategoryDto>();

  const [formData, setFormData] = useState<FormData>(initFormaData);

  useEffect(() => {
    if (id === undefined || isNaN(Number(id))) {
      return;
    }
    setAccCatFetching(true);
    authorizedCall(
      NostroCategoryControllerService.getNostroCategory,
      {
        nostroCategoryPathId: Number(id),
      },
      true
    )
      .then((dto: NostroCategoryDtoIdx) => {
        console.log("getNostroCategory response", dto);
        const newFormData: FormData = initFormaData();
        Object.keys(dto).forEach((key: string) => {
          console.log(
            "DEBUG - ",
            "key:",
            key,
            "typeof:",
            typeof dto[key],
            "val:",
            dto[key]
          );
          newFormData[key].value = dto[key];
        });
        setFormData(newFormData);
      })
      .catch((error: any) => {
        console.log("error in getNostroCategory", error);
      })
      .finally(() => {
        setAccCatFetching(false);
      });
  }, [id, dispatch]);

  function isNotEmpty(value: string) {
    if (typeof value === "number") return true;
    return value.trim() !== "";
  }

  function validate(): boolean {
    let valid: boolean = true;

    if (!formData.code || (formData.code && !isNotEmpty(formData.code.value))) {
      updateformDataImpl("code", "textError", "Required field");
      updateformDataImpl("code", "state", "invalid");
      valid = false;
    }

    if (!formData.text || (formData.text && !isNotEmpty(formData.text.value))) {
      updateformDataImpl("text", "textError", "Required field");
      updateformDataImpl("text", "state", "invalid");
      valid = false;
    }

    if (
      !formData.statusId ||
      formData.statusId.value === 0 ||
      (formData.statusId && !isNotEmpty(formData.statusId.value))
    ) {
      updateformDataImpl("statusId", "textError", "Required field");
      updateformDataImpl("statusId", "state", "invalid");
      valid = false;
    }

    /*if (!formData.comment || (formData.comment && !isNotEmpty(formData.comment.value))) {
            updateformDataImpl('comment', 'textError', 'Required field');
            updateformDataImpl('comment', 'state', 'invalid');
            valid = false;
        }*/

    return valid;
  }

  function submitHandler() {
    if (!validate()) {
      return;
    }

    setAccCatFetching(true);
    const promise =
      id === undefined
        ? authorizedCall(NostroCategoryControllerService.createNostroCategory, {
          requestBody: {
            code: String(formData?.code.value),
            comment: String(formData?.comment.value),
            text: String(formData?.text.value),
            status: Number(formData?.statusId.value),
          },
        })
        : authorizedCall(NostroCategoryControllerService.updateNostroCategory, {
          nostroCategoryPathId: formData!.id.value,
          requestBody: {
            comment: String(formData?.comment.value),
            text: String(formData?.text.value),
            status: Number(formData?.statusId.value),
          },
        });

    promise
      .then(() => {
        navigate("/acc-cat-view");
      })
      .catch((error) => {
        const err: AppError = {
          code: error.status,
          message: error.error?.toString(),
        };
        dispatch(appStateActions.error(err));
      })
      .finally(() => {
        setAccCatFetching(false);
      });
  }

  function updateformDataImpl(field: string, subfield: string, value: any) {
    setFormData((prev: FormData) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [subfield]: value,
      },
    }));
  }

  function updateformDataValue(event: any) {
    const { name, value } = event.target;

    updateformDataImpl(name, "value", value);
  }

  function focusOnInputHandler(event: any) {
    const { name } = event.target;

    updateformDataImpl(name, "textError", undefined);
    updateformDataImpl(name, "state", undefined);
  }

  console.log(
    "RENDER - AccountCategoriesEdit [accCatFetching:",
    accCatFetching,
    "]"
  );

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "View Nostro-CAT", route: "/acc-cat-view" },
        { name: "ACC-CAT" },
      ]}
    >
      <div className="content p-4 bg-white">
        <div className="flex flex-row">
          <div className="basis-1/2">
            <p
              className="heading--three text-xl"
              data-testid="acc-cat-pagetitle"
            >
              ACC-CAT
            </p>
          </div>
          <div className="basis-1/2 text-right">
            <ZfTextButton
              hierarchy="tertiary"
              onClick={() => navigate(`/acc-cat-view`)}
              size="small"
            >
              Cancel
            </ZfTextButton>
            <ZfTextButton
              onClick={submitHandler}
              data-testid="acc-cat-save-btn"
            >
              Save
            </ZfTextButton>
          </div>
        </div>

        {accCatFetching && (
          <div
            id="spinnerContainer"
            data-testid="spinner"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <ZfLoadingSpinner
              size="large"
              color="primary"
              type="infinite"
            ></ZfLoadingSpinner>
          </div>
        )}

        {!accCatFetching && (
          <div className="mt-4" data-testid="acc-cat-form">
            <div className="grid grid-cols-3 gap-4">
              {id && !isNaN(Number(id)) && (
                <>
                  <ZfInputField
                    label="IDi"
                    value={String(formData?.id.value)}
                    placeholder=""
                    state="readonly"
                  ></ZfInputField>

                  <ZfInputField
                    label="Code"
                    name="code"
                    data-testid="acc-cat-code"
                    value={String(formData?.code.value)}
                    placeholder=""
                    state="readonly"
                  ></ZfInputField>

                  <ZfInputField
                    label="LOG_Create_at"
                    value={String(formData?.createTs.value)}
                    placeholder=""
                    state="readonly"
                  ></ZfInputField>
                  <ZfInputField
                    label="LOG_cr"
                    data-testid="acc-cat-logcr"
                    value={String(formData?.createUser.value)}
                    placeholder=""
                    state="readonly"
                  ></ZfInputField>
                </>
              )}

              {(!id || isNaN(Number(id))) && (
                <ZfInputField
                  label="Code"
                  mandatory
                  name="code"
                  data-testid="acc-cat-code"
                  value={String(formData?.code.value)}
                  errorText={formData.code.textError}
                  state={formData.code.state}
                  onFocus={focusOnInputHandler}
                  onBlur={updateformDataValue}
                  placeholder=""
                ></ZfInputField>
              )}

              <ZfSelectField
                mandatory
                name="statusId"
                label="Status"
                value={String(formData?.statusId.value)}
                errorText={formData.statusId.textError}
                state={formData.statusId.state}
                onFocus={focusOnInputHandler}
                onSelectFieldChange={updateformDataValue}
                defaultValue={0}
              //helperText="These cars are for sale"
              >
                <ZfSelectOption value="2">del</ZfSelectOption>
                <ZfSelectOption value="1">check</ZfSelectOption>
              </ZfSelectField>
              <ZfInputField
                label="Text"
                value={formData?.text && String(formData?.text?.value)}
                placeholder=""
                name="text"
                mandatory
                errorText={formData.text.textError}
                state={formData.text.state}
                onFocus={(event) => focusOnInputHandler(event)}
                onBlur={(event) => updateformDataValue(event)}
              ></ZfInputField>
            </div>
            <ZfTextareaField
              name="comment"
              data-testid="acc-cat-comment"
              value={String(formData?.comment.value)}
              errorText={formData.comment.textError}
              state={formData.comment.state}
              onFocus={focusOnInputHandler}
              onBlur={updateformDataValue}
              label="Comment"
              //helperText="Helper text."
              className="mt-3"
              //mandatory
              maxlength={500}
            ></ZfTextareaField>
          </div>
        )}
      </div>
    </AuthenticatedLayout>
  );
};

export default AccountCategoriesEdit;
