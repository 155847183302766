import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authorizedCall from "../../utils/authorizedCallUtils";
import { EmailControllerService, EmailTemplateDto } from "../../openapi";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import {
  ZfIconButton,
  ZfInputField,
  ZfInternalLabel,
  ZfLoadingSpinner,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AppError, appStateActions } from "../../store/appstate-slice";
import { useAppDispatch } from "../../store/store";
import TextEditor from "../common/components/TextEditor/textEditor";

export const EmailTemplatePage = ({
  type,
}: {
  type: "WITHOUT_BALANCE" | "WITH_BALANCE";
}) => {
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(false);
  const [templateDto, setTemplateDto] = useState<EmailTemplateDto>();
  const [template, setTemplate] = useState<string>("");
  const dispatch = useAppDispatch();

  const getEmailTemplate = useCallback(() => {
    setIsFetching(true);

    authorizedCall(
      EmailControllerService.getEmailTemplate,
      { emailTypeEnum: type },
      true
    )
      .then((dto: EmailTemplateDto) => {
        //console.log("getEmailTemplate response", dto);
        setTemplateDto(dto);
        setTemplate(dto.text || "");
      })
      .catch((error: any) => {
        //console.log("error in getEmailTemplate", error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [type]);

  useEffect(() => {
    getEmailTemplate();
  }, [getEmailTemplate]);

  function submitHandler() {
    setIsFetching(true);
    authorizedCall(EmailControllerService.updateEmailTemplate, {
      requestBody: {
        id: type,
        text: template,
      },
    })
      .catch((error) => {
        const err: AppError = {
          code: error.status,
          message: error.error?.toString(),
        };
        dispatch(appStateActions.error(err));
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  // const style = {
  //   overflow: "auto",
  //   border: "solid 1px var(--color-action-secondary-40)",
  //   borderRadius: "var(--border-radius-sm)",
  // };

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        {
          name:
            "Email Template" +
            (type === "WITHOUT_BALANCE" ? "Without Balance" : "With Balance"),
          route:
            "/email-" +
            (type === "WITHOUT_BALANCE" ? "wo" : "w") +
            "-bal-templ",
        },
      ]}
    >
      <div className="content p-4 bg-white">
        <div className="flex justify-between">
          <div>
            <p
              className="heading--three text-xl"
              data-testid="acc-cat-pagetitle"
            >
              {"Email Template " +
                (type === "WITHOUT_BALANCE"
                  ? "Without Balance"
                  : "With Balance")}
            </p>
          </div>
          <div className="flex">
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-2"
              onClick={() => navigate("/")}
            ></ZfIconButton>
            <ZfTextButton
              hierarchy="secondary"
              onClick={getEmailTemplate}
              className="mr-2"
            >
              Cancel
            </ZfTextButton>
            <ZfTextButton
              onClick={submitHandler}
              data-testid="tmpl-save-btn"
              className="mr-2"
              size="small"
            >
              Save
            </ZfTextButton>
          </div>
        </div>

        {isFetching && (
          <div
            id="spinnerContainer"
            data-testid="spinner"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <ZfLoadingSpinner
              size="large"
              color="primary"
              type="infinite"
            ></ZfLoadingSpinner>
          </div>
        )}

        {!isFetching && (
          <>
            <div className="mt-4" data-testid="comment-edit">
              <div className="pb-3">
                <ZfInputField
                  label="User Name"
                  value={String(templateDto?.updateUser)}
                  placeholder=""
                  state="readonly"
                ></ZfInputField>
                <ZfInputField
                  label="Date"
                  value={String(templateDto?.updateTs)}
                  placeholder=""
                  state="readonly"
                ></ZfInputField>
                <div className="form-field__header form-field--header-padding">
                  <ZfInternalLabel>
                    <label className="zf-label">
                      <span className="text">Template</span>
                    </label>
                  </ZfInternalLabel>
                </div>
                {/* <div style={style}>
                  <div
                    style={{
                      padding: "10px",
                    }}
                  > */}
                <TextEditor
                  content={template}
                  setContent={setTemplate}
                  showPreview={false}
                />
              </div>
              {/* </div> 
              </div>*/}
            </div>
          </>
        )}
      </div>
    </AuthenticatedLayout>
  );
};
