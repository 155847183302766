import { useEffect, useRef, useState } from "react";
import {
  ZfDatePicker,
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { useNavigate } from "react-router-dom";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EmailControllerService,
  EntityModelEmailPreviewDto,
} from "../../openapi";

const EmailPage = ({ type }: { type: "WITHOUT_BALANCE" | "WITH_BALANCE" }) => {
  const navigate = useNavigate();

  const dataTable = useRef<HTMLZfTableElement>(null);
  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);

  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [selected, setSelected] = useState<EntityModelEmailPreviewDto[]>([]);
  const [datepicker, setDatepicker] = useState<string>("");

  /**
   *
   * @param url the url of the request
   * @param config the ajaxConfig object
   * @param params the ajaxParams object
   * @returns
   */
  async function dataTableApiCall(url: any, config: any, params: any) {
    setIsFetching(true);
    const sortArray: Array<string> =
      params.sort.length > 0
        ? [`${params.sort[0].field},${params.sort[0].dir}`]
        : [];
    let apiParams: Parameters<
      typeof EmailControllerService.getEmailPreview
    >[0] = {
      page: params.page,
      size: params.size,
      sort: sortArray,
    };

    if (searchFilter.current?.value) {
      apiParams = { ...apiParams, search: searchFilter.current?.value };
    }

    return authorizedCall(
      EmailControllerService.getEmailPreview,
      apiParams,
      true
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  /**
   *
   * First configuration after renderization
   */
  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfCheckboxRowSelection",
        titleFormatter: "zfCheckboxTitleRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "KUBE UPN", field: "upn" /*, headerFilter: 'input'*/ },
      { title: "KUBE Name", field: "name" },
      { title: "Nostro Count", field: "nostroCount" },
      { title: "Account Balance Count", field: "accountBalanceCount" },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, []);

  function filteredSearch(event: CustomEvent) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function rowSelectionChanged(rowSelectionChanged: CustomEvent) {
    setSelected(rowSelectionChanged.detail.data);
  }

  function sendMail(all: boolean) {
    setIsFetching(true);
    authorizedCall(EmailControllerService.sendEmail, {
      requestBody: {
        type: type,
        cobDate: datepicker,
        rmIdList: all
          ? []
          : selected.map((value: EntityModelEmailPreviewDto) => {
            return value.id;
          }),
      },
    })
      .then(() => {
        dataTable.current!.options = { ...dataTable.current!.options };
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  console.log("RENDER - AccountCategories [isFetching:", isFetching, "]");

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        {
          name:
            "Email " +
            (type === "WITHOUT_BALANCE" ? "Without Balance" : "With Balance"),
          route:
            "/email-" +
            (type === "WITHOUT_BALANCE" ? "without-balance" : "with-balance"),
        },
      ]}
    >
      <div className="content p-4 bg-white">
        <div
          className="flex flex-row pb-6"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="basis-1/2">
            <p
              className="heading--two text-xl"
              data-testid="view-acc-cat-pagetitle"
            >
              {"Email " +
                (type === "WITHOUT_BALANCE"
                  ? "Without Balance"
                  : "With Balance")}
            </p>
          </div>
          <div className="basis-1/4">
            <ZfSearchField
              placeholder="Search ..."
              onInputChange={filteredSearch}
              ref={searchFilter}
            ></ZfSearchField>
          </div>
          <div className="basis-1/4"></div>
          <div className="basis-1/4 flex flex-row-reverse">
            <ZfTextButton
              hierarchy="secondary"
              disabled={
                isFetching || (type === "WITH_BALANCE" && datepicker === "")
              }
              onClick={(_) => sendMail(true)}
              className="mr-2"
              size="small"
            >
              Send All
            </ZfTextButton>
            <ZfTextButton
              hierarchy="secondary"
              disabled={
                !selected.length ||
                isFetching ||
                (type === "WITH_BALANCE" && datepicker === "")
              }
              onClick={(_) => sendMail(false)}
              className="mr-2"
              size="small"
            >
              Send Selected
            </ZfTextButton>
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-1"
              onClick={() => navigate(`/`)}
              size="small"
            ></ZfIconButton>
          </div>
        </div>
        {error && <p className="error">Error in loading data</p>}
        {isFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}

        {type === "WITH_BALANCE" && (
          <ZfDatePicker
            mandatory
            value={datepicker}
            label="Select a cob date"
            helperText="Use the keyboard or pick a date from the calendar"
            onValueChange={(e) => setDatepicker(e.detail)}
          />
        )}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) => tableBuiltHandler(event, setIsFetching)}
          onTableRowSelectionChanged={rowSelectionChanged}
          hidden={type === "WITH_BALANCE" && datepicker === ""}
          className="mt-5"
          style={{
            pointerEvents: isFetching && "none",
            opacity: isFetching && 0.7,
          }}
          data-testid="email-wob-data-table"
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};

export default EmailPage;
