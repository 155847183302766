import { ZfLoadingSpinner, ZfTable, ZfTextButton } from "@ccx/zafire-react";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import React, { useCallback, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelAccountLimitDto,
  EntityModelStatusDto,
  NostroControllerService,
  StatusControllerService,
} from "../../openapi";
import { useNavigate } from "react-router-dom";
import { AppError, appStateActions } from "../../store/appstate-slice";
import { useAppDispatch } from "../../store/store";

const cols = [
  "kstaValueCcyLimitLow",
  "kstaValueCcyLimitHigh",
  "busaldoValueCcyLimitLow",
  "busaldoValueCcyLimitHigh",
  "statusText",
];

export const AccountViewEditLimits: React.FC<{ accId: number }> = (props) => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const dataTable = useRef<HTMLZfTableElement>(null);
  const [selected, setSelected] = useState<any>(undefined);
  const [statuses, setStatuses] = useState<EntityModelStatusDto[]>();

  const [limitFetching, setLimitFetching] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const getStatuses = () => {
    console.log("getStatuses");

    setLimitFetching(true);
    //const sortArray: Array<string> = params.sort.length > 0 ? [params.sort[0].field, params.sort[0].dir] : [];
    return authorizedCall(StatusControllerService.getAllStatuses, {
      page: 0,
      size: 100,
    })
      .then((resp) => {
        console.log("resp", resp);
        setStatuses(resp);
      })
      .finally(() => {
        setLimitFetching(false);
      });
  };

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "ID", field: "id" },
      { title: "ZR", field: "accountNostroNumber" },
      { title: "KTO_NR", field: "accountNumber" },
      { title: "CCY", field: "accountCurrencyLabel" },
      {
        title: "KSTA_VAL Low limit",
        field: "kstaValueCcyLimitLow",
        editor: "number",
        editable: false,
      },
      {
        title: "KSTA_VAL High limit",
        field: "kstaValueCcyLimitHigh",
        editor: "number",
        editable: false,
      },
      {
        title: "BuSaldo VAL Low Limit",
        field: "busaldoValueCcyLimitLow",
        editor: "number",
        editable: false,
      },
      {
        title: "BuSaldo VAL High Limit",
        field: "busaldoValueCcyLimitHigh",
        editor: "number",
        editable: false,
      },
      {
        title: "Status",
        field: "statusText",
        editor: "list",
        editorParams: {
          values: statuses && statuses.map((elem: any) => elem.statusText),
        },
        editable: false,
      },
    ];

    const actionColumns: [] = [];

    async function dataTableApiCall(url: any, config: any, params: any) {
      console.log("dataTableCall");

      setLimitFetching(true);
      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];
      let apiParams: Parameters<
        typeof NostroControllerService.getAllAccountLimitForNostro
      >[0] = {
        nostroPathId: props.accId,
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      return authorizedCall(
        NostroControllerService.getAllAccountLimitForNostro,
        apiParams,
        true
      )
        .then((resp) => {
          return resp;
        })
        .finally(() => {
          setLimitFetching(false);
        });
    }

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, [props.accId, statuses]);

  const setEditableColumn = useCallback(
    () =>
      selected
        ?.getCells()
        .filter((cell: any) => cols.includes(cell.getField()))
        .forEach((cell: any) =>
          cell.getColumn().updateDefinition({
            ...cell.getColumn().getDefinition(),
            editable: (cell: any) =>
              editMode && cell.getRow().getIndex() === selected.getIndex(),
          })
        ),
    [selected, editMode]
  );

  useEffect(() => setEditableColumn(), [setEditableColumn]);

  function refreshTable(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function tableRowSelected(row: CustomEvent) {
    setSelected(row.detail);
    setEditMode(false);
  }

  const clickEditHandler = () => {
    setEditMode(true);
  };

  const clickCancelHandler = () => {
    refreshTable("");
    setEditMode(false);
  };

  const clickSaveHandler = () => {
    console.log(selected.getData());
    const limit: EntityModelAccountLimitDto = selected.getData();
    setLimitFetching(true);
    authorizedCall(NostroControllerService.updateAccountLimit, {
      nostroPathId: props.accId,
      limitId: limit.id,
      requestBody: {
        kstaValueCcyLimitLow: Number(limit.kstaValueCcyLimitLow),
        kstaValueCcyLimitHigh: Number(limit.busaldoValueCcyLimitHigh),
        busaldoValueCcyLimitLow: Number(limit.busaldoValueCcyLimitLow),
        busaldoValueCcyLimitHigh: Number(limit.busaldoValueCcyLimitHigh),
        status:
          statuses?.filter(
            (value: EntityModelStatusDto) =>
              value.statusText === limit.statusText
          )[0].statusId || limit.statusId,
      },
    })
      .then(() => {
        setEditMode(false);
      })
      .catch((error) => {
        const err: AppError = {
          code: error.status,
          message: error.error?.toString(),
        };
        dispatch(appStateActions.error(err));
      })
      .finally(() => {
        setLimitFetching(false);
      });
  };

  console.log(
    "RENDER - AccountViewEditLimits [limitFetching:",
    limitFetching,
    "]"
  );

  return (
    <>
      <div>
        <div className="flex flex-row-reverse">
          <ZfTextButton
            hierarchy="primary"
            onClick={() => navigate(`/acc-limit-edit/${props.accId}/new`)}
            size="small"
          >
            Create
          </ZfTextButton>
          <ZfTextButton
            disabled={selected === undefined}
            onClick={(editMode && clickSaveHandler) || clickEditHandler}
            hierarchy="secondary"
            className="mr-1"
            size="small"
          >
            {(editMode && "Save") || "Edit"}
          </ZfTextButton>
          {editMode && (
            <ZfTextButton
              onClick={clickCancelHandler}
              hierarchy="secondary"
              className="mr-1"
              size="small"
            >
              Cancel
            </ZfTextButton>
          )}
        </div>
      </div>

      {error && <p className="error">Error in loading data</p>}
      {limitFetching && (
        <ZfLoadingSpinner
          size="large"
          color="primary"
          type="infinite"
          style={{
            position: "absolute",
            left: "50%",
            right: "50%",
            zIndex: "100",
            top: "35%",
          }}
          data-testid="spinner"
        ></ZfLoadingSpinner>
      )}

      <ZfTable
        ref={dataTable}
        onTableBuilt={(event) => tableBuiltHandler(event, setLimitFetching)}
        onTableRowSelected={tableRowSelected}
        className="mt-5"
        data-testid="acc-lim-data-table"
        style={{
          pointerEvents: limitFetching && "none",
          opacity: limitFetching && 0.7,
        }}
      ></ZfTable>
    </>
  );
};
