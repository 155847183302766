import { ZfTabs } from "@ccx/zafire-react";
import { FC, useState } from "react";
import { AccountViewEditComments } from "./AccountViewEditComments";
import { AccountViewEditAccountBalance } from "./AccountViewEditAccountBalance";
import { AccountViewEditAccountBalanceHistory } from "./AccountViewEditAccountBalanceHistory";
import { AccountViewEditLimits } from "./AccountViewEditLimits";

export const AccountViewEditTabs: FC<{ accId: number }> = (props) => {
  const [selectedTab, setSelectedTab] = useState("comments");

  function selectTab() {
    switch (selectedTab) {
      case "comments":
        return (
          <div>
            <AccountViewEditComments accId={props.accId} />
          </div>
        );
      case "kto-limits":
        return (
          <div>
            <AccountViewEditLimits accId={props.accId} />
          </div>
        );
      case "balance-crr":
        return (
          <div>
            <AccountViewEditAccountBalance accId={props.accId} />
          </div>
        );
      case "balance-hst":
        return (
          <div>
            <AccountViewEditAccountBalanceHistory accId={props.accId} />
          </div>
        );
      default:
        return <div>To develop</div>;
    }
  }

  return (
    <ZfTabs
      size="medium"
      selectedTabId={selectedTab}
      tabs={[
        {
          id: "comments",
          title: "COMMENTS",
        },
        {
          id: "kto-limits",
          title: "KTO-LIMITS",
        },
        {
          id: "balance-crr",
          title: "BALANCE/CRR",
        },
        {
          id: "balance-hst",
          title: "BALANCE/HIST",
        },
      ]}
      onTabSelect={(e) => setSelectedTab(e.detail)}
    >
      {selectTab()}
    </ZfTabs>
  );
};
